import React from "react"
import { FooterPageLayout } from "../components/Global/Layouts"

const ShippingPage = () => (
  <FooterPageLayout
    type={"shipping"}
    title={"Shipping Policy"}
    description={"The shipping policy page :o"}
    query={"query { shop { shippingPolicy { body } } }"}
  />
)

export default ShippingPage
